import {mapGetters, mapActions, mapMutations} from 'vuex';

import ordersNew from './components/orders-new/index.vue';
import ordersCollecting from './components/orders-collecting/index.vue';
import ordersAwaiting from './components/orders-awaiting/index.vue';
import ordersFinished from './components/orders-finished/index.vue';

import {assign, isEmpty, findIndex} from 'lodash'

export default {
  name: 'orders.list',
  data() {
    return {
      currentOrderType: 0,
      activatedTab: 'new',
      tabs: ['new','collecting','awaiting','finished']
    }
  },
  components: {
    ordersNew,
    ordersCollecting,
    ordersAwaiting,
    ordersFinished,
  },
  created() {
    if (isEmpty(this.$route.query)) {
      let query = {
        status: this.activatedTab,
        page: 1
      }
      this.$router.replace({name: 'orders.list', query: assign({}, this.$route.query, query)}).catch(() => {console.log()});
    } else {
      this.activatedTab = this.$route.query.status;
      this.currentOrderType = findIndex(this.tabs, tab => {
        return tab === this.$route.query.status;
      });
    }
  },
  mounted() {},
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {
    ...mapActions({}),
    ...mapMutations({}),
    activateTab(type) {
      this.activatedTab = type;

      let query = {
        status: this.activatedTab,
        page: 1
      }

      this.$router.push({name: 'orders.list', query: assign({}, query)}).catch(() => {console.log()});
    },
  },
  destroyed() {},
}
