import {mapGetters, mapActions} from 'vuex';
import {assign} from "lodash";

export default {
  name: 'ordersFinished',
  data() {
    return {}
  },
  components: {},
  created() {
    if (!('page' in this.$route.query)) {
      this.setUrlParams({page: 1});
    } else {
      this.fetchFinishedOrders(this.$route.query);
    }
  },
  mounted() {},
  computed: {
    ...mapGetters({
      ordersFinishedList: 'orders/ordersFinishedList',
      isOrdersFinishedLoading: 'orders/isOrdersFinishedLoading',
      ordersFinishedPage: 'orders/ordersFinishedPage',
      ordersFinishedPageLength: 'orders/ordersFinishedPageLength',
    }),
  },
  watch: {
    '$route.query': {
      handler: function (val) {
        if (val.page) {
          this.fetchFinishedOrders(this.$route.query);
        }
      }
    },
  },
  methods: {
    ...mapActions({
      fetchFinishedOrders: 'orders/ORDERS_REQUEST_FINISHED_LIST_GET',
    }),
    getClient(item) {
      return item.address
    },
    setUrlParams(query = {}) {
      this.$router.push({name: 'orders.list', query: assign({}, this.$route.query, query)}).catch(() => {
        console.log()
      });
    },

    getPage(pageId) {
      this.setUrlParams({page: pageId})
    },
    show(order) {
      this.$router.push({name: 'orders.show', params: {orderId: order.id}}).catch(() => {console.log();});
    }
  },
  destroyed() {},
}
